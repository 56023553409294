/* Spinner CSS */
.spinner {
    margin: 100px auto;
    width: 50px;
    height: 50px;
    position: relative;
    border: 3px solid transparent;
    border-top-color: #00adb5;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Full-page spinner overlay */
  .full-page-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white */
    display: flex;
    justify-content: center;
    z-index: 10000000;
    align-items: center;
  }
  